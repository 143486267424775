.panel {
    color: white;
    padding: 10px;
}

.panel-blue {
    background-color: #5692CE;
    border-color: #5692CE;

}

.panel-dark {
    background-color: #33334B;
    border-color: #33334B;
}

.panel-purple {
    background-color: #6270C4;
    border-color: #6270C4;
}

.panel-brown {
    background-color: #A8786D;
    border-color: #A8786D;
}