* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent !important;
}

.ui-provider{
  background-color: transparent !important;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 350px;
  max-width: 350px;
  
  /*position: fixed;
  z-index: 1000;
  height: 100%;
  margin-left: -250px;
  background: #2f4050;
  transition: all .3s;*/

}

#sidebar.active {
  margin-left: 0;
}


#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul a li {
  padding: 10px;
  font-size: 1em;
  display: block;
}
#sidebar li.active {
  background-color: #ec6527;
  border-radius: 4px;
  margin-bottom: .2rem;
}
#sidebar ul a li:hover{
  background-color: rgba(0,0,0,.20);
  border-radius: 4px;
}

#sidebar a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.full-height {
  height: 100vh;
}

.content {
  margin-left: 1.125rem;
}


.ui-dropdown__container {
  max-width: 100% !important;
}

.col-shadow{
  box-shadow: rgb(34 36 38 / 15%) 0px 1px 1px 1px;
}

.single-character-column{
  min-width: 1em;
  max-width: 1em;
}


.xfer-box{
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}