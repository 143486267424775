.left-rail {
    border-right: 1px solid #e0e0e0;
}

.left-rail-header {
    display: flex;
    height: 3.5rem;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
}


.rail-item {
    border-bottom: 1px solid #e0e0e0;
}


.left-rail-header button {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2.6rem;
    background-color: transparent !important;
    border: 0px;
    box-shadow: none;
}

.left-rail-header button span {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2.6rem;
    /* background-color: transparent !important; */
}


.menu-container {
    z-index: 1000;
}

